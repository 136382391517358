<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/profile"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.profileOffline.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button>
            <ion-icon
              slot="icon-only"
              :icon="icons.wifiOutline"
              :color="
                networkStatus && networkStatus.connected ? 'success' : 'danger'
              "
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <div class="ion-padding ion-text-center">
        <div style="font-size: 48px">
          <ion-icon
            :src="`${s3Url}/medias/images/icons/icon-no-wifi.svg`"
            slot="start"
          ></ion-icon>
        </div>
        {{ $t('views.profileOffline.description') }}
      </div>
      <div class="ion-padding ion-text-center">
        <ion-button class="ion-margin-bottom" expand="block" @click="onSwitch">
          <ion-icon :icon="icons.cloudOfflineOutline" slot="start"></ion-icon>
          {{ $t('buttons.switchToOfflineMode') }}
        </ion-button>
        <ion-button
          expand="block"
          :disabled="processing"
          @click="onSynchronize"
        >
          <ODNSpinner v-show="processing" slot="end" />
          <ion-icon
            v-show="!processing"
            :icon="icons.cloudDownloadOutline"
            slot="start"
          ></ion-icon>
          <span v-show="!processing">{{ $t('buttons.downloadData') }}</span>
        </ion-button>
        <div>
          <small v-if="synchronized">
            {{
              $t('labels.lastDownload', {
                date: formatDate(synchronized),
              })
            }}
          </small>
          <small v-else>
            {{ $t('labels.noLocalData') }}
          </small>
        </div>
      </div>
      <div class="ion-padding">
        {{ $t('sections.savedItems') }}
      </div>
      <ion-list lines="none">
        <!-- Saved items -->
        <ion-item button :router-link="`/tabs/profile/offline/saved-items`">
          <ion-icon :icon="icons.hourglassOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('labels.waitingForSync') }}
          </ion-label>
          <ion-note slot="end">
            {{ getSavedItemCount }}
          </ion-note>
        </ion-item>
      </ion-list>
      <div class="ion-padding">
        {{ $t('sections.optionSets') }}
      </div>
      <ion-list lines="none">
        <!-- Option Sets -->
        <ion-item v-for="(optionSet, index) in optionSets" :key="index">
          <ion-label>
            {{ $t(optionSet.translationKey) }}
          </ion-label>
          <ion-icon
            v-if="optionSet.synchronized"
            :icon="icons.checkmarkOutline"
            slot="end"
            color="success"
          ></ion-icon>
          <ion-icon
            v-else
            :icon="icons.closeOutline"
            slot="end"
            color="danger"
          ></ion-icon>
        </ion-item>
      </ion-list>
      <div class="ion-padding">
        {{ $t('sections.sections') }}
      </div>
      <ion-list lines="none">
        <!-- Sections -->
        <ion-item v-for="(section, index) in sections" :key="index">
          <ion-icon :icon="section.icon" slot="start"></ion-icon>
          <ion-label>
            {{ $t(section.translationKey) }}
          </ion-label>
          <ion-note v-if="section.synchronized" slot="end">
            {{ section.itemCount }}
          </ion-note>
          <ion-icon
            v-if="section.synchronized"
            :icon="icons.checkmarkOutline"
            slot="end"
            color="success"
          ></ion-icon>
          <ion-icon
            v-else
            :icon="icons.closeOutline"
            slot="end"
            color="danger"
          ></ion-icon>
        </ion-item>
      </ion-list>
      <div v-if="synchronized" class="ion-padding">
        <ion-button
          expand="block"
          color="danger"
          fill="outline"
          @click="onClearData"
        >
          <ion-icon :icon="icons.trashOutline" slot="start"></ion-icon>
          {{ $t('buttons.clearData') }}
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonNote,
  toastController,
} from '@ionic/vue';

import {
  wifiOutline,
  cloudOfflineOutline,
  cloudDownloadOutline,
  checkmarkOutline,
  closeOutline,
  trashOutline,
  hourglassOutline,
} from 'ionicons/icons';

import ODNSpinner from '@c/odn-spinner.vue';

export default {
  name: 'ProfileOffline',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    IonButton,
    IonNote,
    ODNSpinner,
  },
  data() {
    return {
      processing: false,
      icons: {
        wifiOutline,
        cloudOfflineOutline,
        cloudDownloadOutline,
        checkmarkOutline,
        closeOutline,
        trashOutline,
        hourglassOutline,
      },
    };
  },
  computed: {
    ...mapState('offline', [
      'optionSets',
      'sections',
      'synchronized',
      'networkStatus',
    ]),
    ...mapGetters('offline', ['getSavedItemCount']),
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  methods: {
    ...mapActions('offline', ['synchronize', 'clearState']),
    formatDate(dt) {
      if (!dt) return null;
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    onSwitch() {
      this.$router.replace('/offline/stores');
    },
    async onSynchronize() {
      this.processing = true;
      try {
        await this.synchronize();
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.synchronize.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.processing = false;
      }
    },
    async onClearData() {
      this.processing = true;
      try {
        await this.clearState();
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.synchronize.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.processing = false;
      }
    },
  },
};
</script>
